import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, DollarSign, FileDown, Package, PackageCheck, PackageIcon, PackageSearch, ShoppingBag, ShoppingBasket, Truck, TruckIcon } from 'lucide-react';
import Footer from '../Footer';
import Notification_nav from './Notification_nav';


const Notifications = () => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
      const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
const[state,setState] = React.useState({customer:{}})
const[emailturn , setemailturn] = React.useState(false)
const[status , setstatus] = React.useState({booking_confirmation:false , pickup_completed:false, out_for_delivery:false,delivered:false })
console.log("status statwe == " , status)


    const handleChange =(e)=>{
        console.log("e == " , e.target.checked)
        setemailturn(e.target.checked)
        change_email_notification_status( e.target.checked)

    }

    const axios_get_api = (status) => {
 
        let full_api = config.apiUrl + `/user/customer_detail`;
        let sendData = {
       
        }
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setState({...state,customer:res.data.output})
          if(res.data.output.email_notification!=undefined && res.data.output.email_notification==true){
            setemailturn(true)
          }
          if(res.data.output.email_array!=undefined  ){
            setstatus({
                booking_confirmation:res.data.output.email_array[0].booking_confirmation , pickup_completed:res.data.output.email_array[0].pickup_completed, out_for_delivery:res.data.output.email_array[0].out_for_delivery,delivered:res.data.output.email_array[0].delivered   
            })
          }
        }).catch((e) => {
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])

      const change_email_notification_status = (status) => {
 
        let full_api = config.apiUrl + `/user/customer_email_notification_status_change`;
        let sendData = {
          status : status
        }
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        //   setState({...state,customer:res.data.output})
        if(res.data.status==true){
            axios_get_api()
        }
        else{
            axios_get_api()
        }
        }).catch((e) => {
        })
      }
      const change_shipping_status = (newStatus) => {
        // console.log("statu == " , status)
 
        let full_api = config.apiUrl + `/user/customer_email_notification_shipping_status_change`;
        let sendData = {
          status : newStatus
        }
        console.log("data_to_send" , sendData)
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
        //   setState({...state,customer:res.data.output})
        if(res.data.status==true){
            axios_get_api()
        }
        else{
            axios_get_api()
        }
        }).catch((e) => {
        })
      }

      const handlestatus = (e, statusKey) => {
        const newStatus = { ...status, [statusKey]: e.target.checked };
        setstatus(newStatus);
        change_shipping_status(newStatus);
      };

  
  return (
    <div>
      <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>

         <div className='container'>
         <div class="row mb-4">
                <div class="col-12 col-md">
                    <div class="d-flex">
                       
                        <div class="">
                            <h2>Shipping Notifications</h2>
                        </div>
                    </div>
                </div>
               
            </div>
            <Notification_nav value={1}/>
            <div className='row mt-4'>
                <div className='col-12'>
                  
                   <div class="form-check form-switch">
   <label class="form-check-label" for="flexSwitchCheckDefault">Turn Email Notifications</label>
  <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={(e)=>handleChange(e)} checked={emailturn}/>
</div>
                </div>
            </div>
        
        { state.customer.email_notification && state.customer.email_notification==true?
           <div class=" table-responsive mt-4">
                <table class="table table-bordered table_box billing_table">
                    <thead>
                      <tr>
                        <th>Order Status</th>
                        <th>Disable/Enable</th>
                         </tr>
                    </thead>
                    {/* <tbody>
                     
                      <tr>
                     <td>Pickup Awaited</td>
                     <td>
                     <div class="form-check form-switch">
   <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="pickup_awaited" onChange={(e)=>handlestatus(e,"pickup_awaited")} checked={status.pickup_awaited}/>
                     </div>
                     </td>
                    </tr>
                    <tr>
                     <td>Pickup Scheduled</td>
                     <td>
                     <div class="form-check form-switch">
   <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="pickup_scheduled" onChange={(e)=>handlestatus(e,"pickup_scheduled")} checked={status.pickup_scheduled}/>
                     </div>
                     </td>
                    </tr> <tr>
                     <td>Picked Up</td>
                     <td>
                     <div class="form-check form-switch">
   <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="picked_up" onChange={(e)=>handlestatus(e ,"picked_up")} checked={status.picked_up}/>
                     </div>
                     </td>
                    </tr> <tr>
                     <td>In Transit</td>
                     <td>
                     <div class="form-check form-switch">
   <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="in_transit" onChange={(e)=>handlestatus(e ,"in_transit")} checked={status.in_transit}/>
                     </div>
                     </td>
                    </tr> <tr>
                     <td>Out For Delivery</td>
                     <td>
                     <div class="form-check form-switch">
   <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="out_for_delivery" onChange={(e)=>handlestatus(e,"out_for_delivery" )} checked={status.out_for_delivery}/>
                     </div>
                     </td>
                    </tr>
                    <tr>
                     <td>Delivered</td>
                     <td>
                     <div class="form-check form-switch">
   <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="delivered" onChange={(e)=>handlestatus(e,"delivered")} checked={status.delivered}/>
                     </div>
                     </td>
                    </tr> 
                     <tr>
                     <td>Failed</td>
                     <td>
                     <div class="form-check form-switch">
   <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" name="failed" onChange={(e)=>handlestatus(e,"failed")} checked={status.failed}/>
                     </div>
                     </td>
                    </tr> 
                      
                     
                   
                    </tbody> */}
                     <tbody>
          {[
            { name: "Booking Confirmation", key: "booking_confirmation" },
            { name: "Pickup Completed", key: "pickup_completed" },
            { name: "Out For Delivery", key: "out_for_delivery" },
            { name: "Delivered", key: "delivered" },
           
          ].map((statusItem) => (
            <tr key={statusItem.key}>
              <td>{statusItem.name}</td>
              <td>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`flexSwitchCheckDefault-${statusItem.key}`}
                    name={statusItem.key}
                    onChange={(e) => handlestatus(e, statusItem.key)}
                    checked={status[statusItem.key]}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
                </table>  
            </div>
          :""}

         </div> 
          </div>
          <Footer/>
    </div>
  )
}

export default Notifications

import React, {  useRef } from 'react'

import axios from "axios";

import config from "../../config"

import { useNetworkState } from 'react-use';



const Notification_nav = (props) => {
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const[state,setState] = React.useState({output:{}})
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
 
    const axios_get_api = (status) => {
 
        let full_api = config.apiUrl + `/user/company_detail_api`;
        let sendData = {
       
        }
      axios.post(full_api, sendData, { headers: config.headers }).then(res => {
          setState({...state,output:res.data.output})
        }).catch((e) => {
        })
      }
      React.useEffect(() => {
        axios_get_api()
      }, [])
     
   
    const { value } = props;
    
  return (
    <div>
      <section class="mb-3">
          <div class="">
           
            <div>
                <ul class="nav nav-pills">
                    {state.output.email_notification==true?
                    <li class="nav-item">
                      <a    className={value === 1 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
            href='/customer/email_notifications'>Email Notification</a>
                    </li>
                    :""}

{state.output.whatsapp_notification==true?
                    <li class="nav-item">
                      <a   className={value === 2 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
           href='/customer/whatsapp_notifications' >Whatsapp Notification</a>
                    </li>
                    :""}

{state.output.sms_notification==true?
                    <li class="nav-item">
                      <a  className={value == 3 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
           href='/customer/sms_notifications'>Sms Notification</a>
                    </li>
                    :""}
                  </ul>
            </div> 
            
          </div>
        </section>
    </div>
  )
}

export default Notification_nav

import React, { useState, useEffect } from "react";
import axios from "axios";
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Modal from 'react-bootstrap/Modal';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import TablePagination from '@mui/material/TablePagination';

import Swal from 'sweetalert2'
import { useNavigate, useParams } from 'react-router-dom'
import Left_menu from "./Left_menu";
import Header from "./Header";
import config from "../../config"
import Footer from "../Footer";


const Edit_staff = () => {
    let navigate = useNavigate();
    let staff_id= useParams();
    console.log("staff_id" , staff_id)
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search = params.get('search')
    const [state, setState] = useState({ staff_id:staff_id.staff_id,full_name: "", email: "", phone: "",password:"",shipments:false,orders:false,settings:false,billing:false,support:false,reports:false,tools:false, warehouse:false,weight_discrepancy:false,other:false,staff:false });
    const [otherStates, setOtherState] = useState({ dstatus: "", activePage: 1, rowsPerPage: 10, page: 0, total_count: 0, onload: true });
    const [modalState, setModalState] = useState({ show: false, user_id: "", action: "" });
    const [editState, setEditState] = useState({ postal_id: "", additive: "", postal_code: "", city_id: "", state_id: "", country_id: "" });
    const [newState, setNewState] = useState({ additive: "", postal_code: "" });
    const [searchfilters, setsearchFilters] = useState({ searchtitle: "" });
    const [editModalState, setEditModalState] = useState({ full_name: "", email: "", phone: "", admin_type: "",password:"",shipments:false,customers:false,master_settings:false,system_settings:false,payments:false,users:false,web_management:false,create_shipment:false });
    console.log("editModalState === " , editModalState)
    const [newtModalState, setNewModalState] = useState({full_name: "", email: "", phone: "",password:"",shipments:false,orders:false,settings:false,billing:false,support:false,reports:false,tools:false, warehouse:false,weight_discrepancy:false,staff:false, show: false });
    const [isLoading, setIsLoading] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[radiostate , setradiostate] = React.useState({otp:false})
    console.log("radiostate === " , radiostate )

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })
React.useEffect(()=>{
    getStaffList()
},[])
   

    const getStaffList = () => {

        setIsLoading(true)
        let full_api = config.apiUrl + `/user/staff_detail`;
        let sendData = { staff_id:staff_id.staff_id};

       
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
            setIsLoading(false)
            console.log("response ", res);
          
              setState({ ...state, full_name: res.data.output.full_name, email: res.data.output.email, phone:res.data.output.mobile,password:res.data.output.password,
                shipments:res.data.output.previlage?res.data.output.previlage.shipments:false,
                orders:res.data.output.previlage?res.data.output.previlage.orders:false,
                settings:res.data.output.previlage?res.data.output.previlage.settings:false,
                billing:res.data.output.previlage?res.data.output.previlage.billing:false,
                support:res.data.output.previlage?res.data.output.previlage.support:false,
                reports:res.data.output.previlage?res.data.output.previlage.reports:false,
                tools:res.data.output.previlage?res.data.output.previlage.tools:false, 
                warehouse:res.data.output.previlage?res.data.output.previlage.warehouse:false,
                weight_discrepancy:res.data.output.previlage?res.data.output.previlage.weight_discrepancy:false,
                staff:res.data.output.previlage?res.data.output.previlage.staff:false,
                other:res.data.output.previlage?res.data.output.previlage.other:false,

                 })

        }).catch((e) => {
            setIsLoading(false)

        });
    }

   

  

    const inputHandleChange = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
        if (e.target.type == "file") {
            setNewModalState({ ...newtModalState, [e.target.name]: e.target.files });
        }else if(e.target.type=="checkbox"){
            if (e.target.checked == true) {
                setNewModalState({...newtModalState , [e.target.name]:true })
        } 
       
        else{
            setNewModalState({...newtModalState , [e.target.name]:false })
        }
    }
        else {
            setNewModalState({ ...newtModalState, [e.target.name]: e.target.value });
        }
    }
    const inputHandleChange2 = (e) => {
        // console.log(e.target.name);
        // console.log(e.target.value);
      
            setradiostate({ ...radiostate, [e.target.name]: e.target.checked })
      
    }
    const inputeditHandleChange = (e) => {
        console.log(e.target.name);
        console.log(e.target.checked);
        if (e.target.type == "file") {
            setState({ ...state, [e.target.name]: e.target.files });
        }else if(e.target.type=="checkbox"){
            if (e.target.checked == true) {
                setState({...state , [e.target.name]:true })
        }
        else{
            setState({...state , [e.target.name]:false })
        } 
    }
        else {
            setState({ ...state, [e.target.name]: e.target.value });
        }
    }

   

  
    const newUserEdit = () => {
        
        if (state.full_name != "" && state.email != "" && state.phone != ""  && state.password!="") {
            setIsLoading(true)
            let full_api = config.apiUrl + `/user/edit_staff`;
         
            let bodyData = state;
            console.log("bb", bodyData);
            axios.post(full_api, bodyData, {
                headers: config.headers
            }).then((res) => {
                 setIsLoading(false)
                console.log("res ", res);
                if (res.data.status == true) {
                   
                  navigate('/customer/staff')
                 
                        Toast.fire({
                            background: "#082D79",
                            type: 'success',
                            title: "Updated Successfully !",
                            color: "white"
                        });
                   
                } else {
                    Toast.fire({
                        background: "#e63900",
                        type: 'error',
                        title: res.data.message,
                        color: "white"
                    });
                }
                // navigate('/categories')
                // let newCategory = res.data.category;
                //setState(state.concat([newCategory]))
            }).catch((e) => {
                setIsLoading(false)
                Toast.fire({
                    background: "#e63900",
                    type: 'error',
                    title: "Something Went Wrong",
                    color: "white"
                });
            });

        } else {
            Toast.fire({
                background: "#e63900",
                type: 'error',
                title: "Please fill all data.",
                color: "white"
            });
        }
    }


    return (
        <div>
            
            

          
           
                <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
               <div className="container">
                <div>
            
               
                <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                       
                        <div class="">
                            <h2>Edit Staff</h2>
                        </div>
                    </div>
                </div>
                
            </div>
              
              
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <label for="email">Full name</label>
                            <input className="form-control" id="first_name" type="text" placeholder="Enter name" name="full_name" onChange={(e) => inputeditHandleChange(e)} required  value={state.full_name}/>
                          
                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Email</label>
                            <input className="form-control" id="first_name" type="email" placeholder="Enter Email" name="email" onChange={(e) => inputeditHandleChange(e)} required  value={state.email} autoComplete="off" disabled/>

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Password</label>
                            <input className="form-control" id="first_name" type="password" placeholder="Enter Password" name="password" onChange={(e) => inputeditHandleChange(e)}  required autoComplete="off"/>

                        </div>
                        <div className="col-md-12 mb-3">
                            <label for="email">Phone</label>
                            <input className="form-control" id="first_name" type="Text" name="phone" placeholder="Enter phone" onChange={(e) => inputeditHandleChange(e)}  value={state.phone}/>
                        </div>
                       
                        <div className="col-md-12 mb-3">
                            <label for="email" className="mb-2">Set Privilege</label>
                            <div className="row mb-2">
                            <div className="col-md-3">
                         
                             <input class="form-check-input " type="checkbox" name="shipments" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.shipments==true}/>
                             <small for="email" className="ms-2">Manage Shipments</small> 
                             </div>
                             <div className="col-md-3">
                         
                             <input class="form-check-input " type="checkbox" name="orders" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.orders==true}/>
                             <small for="email" className="ms-2">Manage Orders</small> 
                             </div>
                             <div className="col-md-3">
                           
                           <input class="form-check-input " type="checkbox" name="settings" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.settings==true}/>
                           <small for="email" className="ms-2">Manage Settings</small>
                           </div>
                           <div className="col-md-3">
                          
                          <input class="form-check-input " type="checkbox" name="warehouse" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.warehouse==true}/>
                          <small for="email" className="ms-2">Manage Warehouse</small> 
                          </div>

                            </div>
                          
                            <div className="row mb-2">
                            <div className="col-md-3">
                           
                             <input class="form-check-input " type="checkbox" name="weight_discrepancy" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.weight_discrepancy==true}/>
                             <small for="email" className="ms-2">Manage Weight Discrepancy</small> 
                             </div>
                             <div className="col-md-3">
                          
                             <input class="form-check-input " type="checkbox" name="tools" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.tools==true} />
                             <small for="email" className="ms-2">Manage Tools</small> 
                             </div>
                             <div className="col-md-3">
                          
                          <input class="form-check-input " type="checkbox" name="billing" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.billing==true}/>
                          <small for="email" className="ms-2">Manage Billing</small> 
                          </div>
                          <div className="col-md-3">
                           
                           <input class="form-check-input " type="checkbox" name="support" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.support==true}/>
                           <small for="email" className="ms-2">Manage Support</small> 
                           </div>

                            </div>
                            <div className="row">
                           
                           
                             <div className="col-md-3">
                           
                             <input class="form-check-input " type="checkbox" name="reports" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.reports==true}/>
                             <small for="email" className="ms-2">Manage Reports</small> 
                             </div>
                             <div className="col-md-3">
                           
                           <input class="form-check-input " type="checkbox" name="other" id="inlineRadio1" value="option1" onChange={(e) => inputeditHandleChange(e)} checked={state.other==true}/>
                           <small for="email" className="ms-2">Manage Others</small> 
                           </div>
                             
                            </div>
                         
                        </div>
                    </div>
               
                  
                    <button className="btn btn-primary mt-2"
                        onClick={() =>
                            newUserEdit()
                        }
                        >
                        Edit
                    </button>
                
                </div>
               </div>
                  

                 

                </div>
                <Footer/>
        </div>
    )
}

export default Edit_staff

import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import config from "../../config"
import Moment from 'react-moment';
import ReactStarsRating from 'react-awesome-stars-rating';
// import '../../components/loader.css';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import ReactLoading from 'react-loading';
import { useNetworkState } from 'react-use';
const Customer_shipment_detail = () => {
  const isOnline = useNetworkState();
  console.log("isONline  === " ,isOnline )
   
    const shipment_id = useParams();
  const [state, setState] = React.useState({ shipmentDetail: {sender:{},receiver:{},items:[] }, isLoading: true,detailFound:false,parcel_count:0  })
 
const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
 const [isLoading , setisLoading] = React.useState(true)

 const [trackingstate,setTrackingState] = React.useState({track:{},isLoading:true})
 const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

 const toggleSidebar = () => {
     setIsSidebarOpen(!isSidebarOpen);
     const homeSection = document.querySelector('.home-section');
     if (homeSection) {
         homeSection.classList.toggle('sidebar-closed');
     }
 };
 let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
const dimension_detail = () =>{
  let full_api = config.apiUrl + `/user/get_company_info_api`;
  let sendData = {};

  axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
    // console.log("rnmmmmm", res);
  }).catch((e) => {
    // toast.configure()
     //toast.error("Some thing went wrong")
    // console.log("----error:   ", e);
  })
}

  React.useEffect(() => {
    dimension_detail();
    ShipmentDetail()
    shipment_tracking()
   
   
  }, [])

    const ShipmentDetail = () => {

        let full_api = config.apiUrl + `/shipment/shipment_detail`;
        let sendData = { shipment_id: shipment_id.shipment_id };
        // console.log("bb",sendData);
        axios.post(full_api, sendData, { headers: config.headers }).then((res) => {
          // console.log("res -> ",res.data.output[0]);
        
         
            setState({ ...state, shipmentDetail: res.data.output[0],parcel_count:res.data.output[0].items.length, isLoading:false})
             setisLoading(false)
     
        }).catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong!',
            
          })
          // console.log("----error:   ", e);
        });
      };
      React.useEffect(() => {
        ShipmentDetail();
      }, [])

      const nameFormat = (name)=> {
        
        if(name) {
            let nameArray =  name.split(" ");
           // console.log("nameArray  ",nameArray);
            if(nameArray.length > 1) {
                let n1 = nameArray[0].substring(0,1).toUpperCase();
                let n2 = nameArray[1].substring(0,1).toUpperCase();
                name = n1+n2;
            }else {
                name = nameArray[0].substring(0,2).toUpperCase();
            }
        }else{
            name = "NO";
        }
        return name;
    }
   
  
   

   const shipment_tracking = () =>{
      
    let full_api = config.apiUrl + `/shipment/shipment_tracking`;
   let sendData = {customer_id:userData.customer_id , trackingNumber:shipment_id.shipment_id};
  //  console.log("sendData",sendData)
    
   axios.post(full_api, sendData, { headers: config.headers }).then(res => {
    if(res.data.status==200){
       setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
      //  console.log("res ====",res.data.output)
      //  setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
     
    }
    else{
     setTrackingState({...trackingstate,track:res.data.output,isLoading:false})
    //  console.log("res",trackingstate.track)
    //  setState({ ...state, shipmentDetail: res.data.ship_detail, isLoading:false})
    }
   
    
   }).catch((e) => {
     // toast.configure()
      //toast.error("Some thing went wrong")
    //  console.log("----error:   ", e);
   })
 }
  return (
    <div>
   <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
{isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"80px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
    </div>:
    <section class="mb-3 pt-5">
      <div class="">
        <div class="row order_detail_head mb-3">
          <div class="col-12 col-md-2">
            <h2><small><a href="/customer/shipments"><i class="fa fa-chevron-left" aria-hidden="true"></i></a></small> &nbsp; 
            {state.shipmentDetail.carrier_tracking_number!=undefined && state.shipmentDetail.carrier_tracking_number!=""?state.shipmentDetail.carrier_tracking_number:""}
           </h2>
           <small>{state.shipmentDetail.order_number}</small>
          </div>
          <div class="col-12 col-md-4">
          {state.shipmentDetail.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:state.shipmentDetail.status==1?<span class="badge rounded-pill bg-success fw-400">Pickup Awaited</span>:state.shipmentDetail.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:state.shipmentDetail.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:state.shipmentDetail.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:state.shipmentDetail.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:state.shipmentDetail.status==6?<span class="badge rounded-pill bg-dark fw-400">Cancelled</span>:state.shipmentDetail.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:state.shipmentDetail.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:state.shipmentDetail.status==9?<span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span>:state.shipmentDetail.status==10?<span class="badge rounded-pill bg-danger fw-400">Not Picked</span>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
          </div>
          <div class="col-12 col-md-4">
            <a  class="btn btn-outline-primary btn-sm" href={`/customer/create_ticket?order_number=${encodeURIComponent(state.shipmentDetail.order_number)}`}>Support</a>
          </div>
          
        </div> 
        <div class="row order_box_cont">
            <div class="col-12 col-md">
                <div class="card border-0 shadow-sm mb-3">
                  <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                            <i class="material-icons-outlined">shopping_bag</i>
                        </div>
                        <div class="ps-3 ">
                            <h6>Order Details</h6>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Order created on channel</small>
                            <p><Moment format="MMM DD YYYY">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment></p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Pickup Address</small>
                        <p>{state.shipmentDetail.sender.state} </p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Payment</small>
                            <p>₹ {state.shipmentDetail.total_amount} {state.shipmentDetail.status==6?<span class="badge rounded-pill bg-success">Refunded</span>:<span class="badge rounded-pill bg-success">Success</span>}</p>
                        </div>
                        <div class="col-12 col-md-4 mb-2">
                            <small class="text-muted">Order created on system</small>
                            <p><Moment format="MMM DD YYYY">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment> - <Moment format="hh:mm:ss a">
                        {new Date(state.shipmentDetail.created*1000)}
            </Moment></p>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                            <div class="card-body">
                              <div class="d-flex ">
                                  <div class="border-end pe-3">
                                      <i class="material-icons-outlined">local_shipping</i>
                                  </div>
                                  <div class="ps-3 ">
                                      <h6>Shipping Details</h6>
                                  </div>
                              </div>
                              <hr/>
                                <div class="row">
                                    <div class="col-12 col-md-12 mb-2">
                                        <small class="text-muted">Courier</small>
                                        <p>{state.shipmentDetail.carrier_name}</p>
                                    </div>
                                    <div class="col-12 col-md-6 mb-2">
                                        <small class="text-muted">AWB No.</small>
                                        <p><a href="">{state.shipmentDetail.carrier_tracking_number}</a> </p>
                                    </div>
                                    <div class="col-12 col-md-6 mb-2">
                                        <small class="text-muted">Mode</small>
                                        <p>{state.shipmentDetail.product_type_name}</p>
                                    </div>
                                    <div class="col-12 col-md-6 mb-2">
                                        <small class="text-muted">Courier Rule Applied</small>
                                        <p>N/A</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md">
                        <div class="card border-0 shadow-sm mb-3">
                            <div class="card-body">
                              <div class="d-flex ">
                                  <div class="border-end pe-3">
                                      <i class="material-icons-outlined">person</i>
                                  </div>
                                  <div class="ps-3 ">
                                      <h6>Customer Details</h6>
                                  </div>
                              </div>
                              <hr/>
                              
                              <div class="row">
                                  <div class="col-12 col-md-6 mb-2">
                                      <small class="text-muted">Name</small>
                                      <p>{state.shipmentDetail.receiver.name}</p>
                                  </div>
                                  <div class="col-12 col-md-6 mb-2">
                                      <small class="text-muted">Contact No.</small>
                                      <p>{state.shipmentDetail.receiver.mobile} </p>
                                  </div>
                                  <div class="col-12 col-md-12 mb-2">
                                      <small class="text-muted">Email</small>
                                      <p>{state.shipmentDetail.receiver.email}</p>
                                  </div>
                                  <div class="col-12 col-md-12 mb-2">
                                      <small class="text-muted">Address</small>
                                      <p>{state.shipmentDetail.receiver.address},
                                        {state.shipmentDetail.receiver.city}, {state.shipmentDetail.receiver.state},
                                        {state.shipmentDetail.receiver.pincode}, {state.shipmentDetail.receiver.country}</p>
                                  </div>
                              </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3">
                    <div class="card-body">
                      <div class="d-flex ">
                          <div class="border-end pe-3">
                              <i class="material-icons-outlined">view_in_ar</i>
                          </div>
                          <div class="ps-3 ">
                              <h6>Package Details</h6>
                          </div>
                      </div>
                      <hr/>
                      <div class="row">
                          <div class="col-12 col-md-3 mb-2">
                              <small class="text-muted">Dead Weight (in Kg)</small>
                              <p>{state.shipmentDetail.dead_weight}</p>
                          </div>
                          <div class="col-12 col-md-3 mb-2">
                              <small class="text-muted">Dimensions (in cm)</small>
                              <p>{state.shipmentDetail.height} x {state.shipmentDetail.width} x {state.shipmentDetail.length}</p>
                          </div>
                          <div class="col-12 col-md-3 mb-2">
                              <small class="text-muted">Volumetric Weight (in kg)</small>
                              <p>{state.shipmentDetail.volumetric_weight}</p>
                          </div>
                          <div class="col-12 col-md-3 mb-2">
                              <small class="text-muted">Applied Weight (in Kg)</small>
                              <p>{state.shipmentDetail.total_weight}</p>
                          </div>
                      </div>
                    </div>
                </div>
                <div class="card border-0 shadow-sm mb-3">
                    <div class="card-body">
                      <div class="d-flex ">
                          <div class="border-end pe-3">
                              <i class="material-icons-outlined">shopping_cart</i>
                          </div>
                          <div class="ps-3 ">
                              <h6>Product Details</h6>
                          </div>
                      </div>
                      <hr/>
                      <div class="table-responsive ">
                        <table class="table table-light">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Category</th>
                                <th>HSN</th>
                                <th>SKU</th>
                                <th>Qty</th>
                                <th>Unit price</th>
                                <th>Discount</th>
                                <th>Tax</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                                {state.shipmentDetail.items.map((sub)=>(
                              <tr>
                                <td>{sub.item_name}</td>
                                <td>-------</td>
                                <td>----</td>
                                <td>-----</td>
                                <td>{sub.item_quantity}</td>
                                <td>{sub.item_value}</td>
                                <td>------</td>
                                <td>-----</td>
                                <td></td>
                              </tr>
                              ))}
                            </tbody>
                          </table>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="card border-0 shadow-sm">
                  <div class="card-body">
                    <div class="d-flex ">
                        <div class="border-end pe-3">
                            <i class="material-icons-outlined">search</i>
                        </div>
                        <div class="ps-3 ">
                            <h6>Tracking Info</h6>
                        </div>
                    </div>
                    <hr/>
                    <div  className='mx-2'>
                    {trackingstate.isLoading? <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"80px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
      <ReactLoading type={"spin"} color={"#0d6efd"} height={20} width={60} /></div>:
                      trackingstate.track.history?
                    trackingstate.track.history.length>0?
     <section class="track_box">
       
         <ul id="progress">
           {trackingstate.track.history.map((sub)=>(
             <>
             <li><div class="node green mb-2"></div><p>{sub.message} <small class="ms-3">{sub.location} <Moment format="MMM DD YYYY hh:mm:ss a">
                 {(sub.time)}
     </Moment> </small></p></li>
           {/* <li><div class="divider green"></div></li> */}
           </>
             ))}
         </ul>



     </section>
     :<div className='' style={{marginLeft:"50px"}}>Tracking History Not Found</div>:<div className='' style={{marginLeft:"50px"}}>Tracking History Not Found</div>}</div>
                  </div>
                </div>
            </div>
        </div>     
      </div>
    </section>
        
                                }
   </div>
                                


    {/* <?php include 'footer.php';?> */}
    </div>
  )
}

export default Customer_shipment_detail
